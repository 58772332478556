import React, { FunctionComponent } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { MobileAppDownloadStyle } from './MobileAppDownloadStyle';

interface MobileAppDownloadProps {
  className?: string;
}

const MobileAppDownload: FunctionComponent<MobileAppDownloadProps> = props => {
  const { className } = props;
  return (
    <MobileAppDownloadStyle className={className}>
      {isIOS ? (
        <>
          <a href="https://apps.apple.com/us/app/travelpix/id1516853211" className="appstore">
            <img src="/mobile-app/app-store-badge.png" alt="App Store Download" />
          </a>
        </>
      ) : isAndroid ? (
        <a href="https://play.google.com/store/apps/details?id=com.travelpix.photographer" className="playstore">
          <img src="/mobile-app/google-play-badge.png" alt="Google Play Download" />
        </a>
      ) : (
            <>
              <a href="https://apps.apple.com/us/app/travelpix/id1516853211" className="appstore">
                <img src="/mobile-app/app-store-badge.png" alt="App Store Download" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.travelpix.photographer" className="playstore">
                <img src="/mobile-app/google-play-badge.png" alt="Google Play Download" />
              </a>
            </>
          )}
    </MobileAppDownloadStyle>
  );
};

export default MobileAppDownload;

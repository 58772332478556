import styled from '@emotion/styled';

const PhotographerPageStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 0 auto;

  .apply-icon {
    width: 15.5px;
    height: 10px;
    margin: 0;
  }

  .spacing {
    width: 1em;
  }

  .btn-control {
    padding: 1rem 2.4rem;
    svg {
      font-size: 2rem;
    }
  }

  .break-word {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .primary-text {
    color: #fbca00;
    cursor: pointer;
  }

  p.text-slogan {
    font-family: RubikMedium;
    max-width: 402px;
    font-size: 24px;
    text-align: center;
    margin: 2rem 0 5rem 0;
    line-height: 30px;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .header-container {
    width: 100%;
    padding-top: 120px;
    display: flex;
    justify-content: center;
    background-color: #fbca00;
    @media screen and (max-width: 1024px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    @media screen and (max-width: 768px) {
      .caption {
        font-size: 30px;
        width: 350px;
        line-height: 48px;
      }

      .text-intro {
        width: 350px;
      }

      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    }
    .header-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      flex: 1 0 auto;
      width: 100%;
      max-width: 905px;
      @media screen and (max-width: 1000px) {
        flex-direction: column-reverse;
        padding: 0;
      }

      .left-content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 3em 0;

        @media screen and (max-width: 768px) {
          padding: 2em;
          align-items: center;
        }

        @media screen and (max-width: 425px) {
          padding: 2em;
          align-items: flex-start;
        }

        .main-logo {
          max-height: 40px;
          width: auto;
          margin-bottom: 2em;
          display: none;
          @media screen and (max-width: 768px) {
            display: block;
          }
        }

        .caption {
          font-size: 44px;
          font-family: RubikMedium;
          max-width: 400px;

          @media screen and (max-width: 425px) {
            font-size: 30px;
            line-height: 34px;
            width: 266px;
          }
        }

        .text-intro {
          margin-top: 2rem;
          font-size: 18px;
          line-height: 24px;
          max-width: 415px;
          .price {
            font-family: RubikBold;
          }

          @media screen and (max-width: 425px) {
            font-size: 15px;
            line-height: 24px;
            width: 200px;
          }
        }

        form.group-input {
          width: 100%;
          display: flex;
          margin-top: 2em;
          max-width: 434px;
          flex-wrap: wrap;
          .input-control {
            border: none;
            input {
              font-size: 14px !important;
              font-family: RubikMedium;
            }
          }
          @media screen and (max-width: 1000px) {
            flex-direction: column;
            div {
              &:nth-of-type(n) {
                // margin-bottom: 1em;
              }
            }
          }

          @media screen and (max-width: 768px) {
            max-width: 50%;
          }

          @media screen and (max-width: 425px) {
            max-width: 100%;
          }
        }
        .apply-btn {
          max-width: 170px;
          font-size: 15px;
          padding: 1rem 1.53rem 1rem 1.75rem;
          font-family: RubikMedium;
          float: left;

          .apply-icon {
            margin: 0 0 0 22px;
            width: 15px;
            height: 10px;
          }

          @media screen and (max-width: 1000px) {
            margin-top: 0;
          }
          @media screen and (max-width: 768px) {
            max-width: 100%;
            width: 100%;
            margin-top: 0.5em;
            float: none;
            justify-content: space-between;

            .apply-icon {
              margin: 0 0 0 22px;
              width: 15px;
              height: 10px;
              float: right;
            }
          }
        }
      }

      .right-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 0 auto;
        padding-left: 2em;
        padding-bottom: 4em;

        .img-wrapper {
          width: 100%;
          max-width: 420px;
        }

        @media screen and (max-width: 768px) {
          padding-right: 2em;
          margin-top: -24px;
          padding-bottom: 0;
        }
      }
    }
  }

  .flexible-section {
    display: flex;
    width: 100%;
    justify-content: center;
    .flexible-container {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 905px;

      .flexible-title {
        font-size: 44px;
        line-height: 48px;
        width: 92%;
        text-align: left;
        margin: 0;
        color: #1e2433;
        padding: 2em 1.2em 0.5em 1em;

        @media screen and (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          width: 350px;
          padding: 2em 0 0.5em 0;
        }

        @media screen and (max-width: 425px) {
          font-size: 22px;
          line-height: 28px;
          width: 75%;
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }

      .flexible-subtitle {
        font-size: 15px;
        line-height: 20px;
        font-family: RubikRegular;
        color: #a5a7ad;
        width: 82%;

        @media screen and (max-width: 768px) {
          max-width: 350px;
        }

        @media screen and (max-width: 425px) {
          padding-left: 2rem;
        }
      }

      #flexible-subtitle-web {
        display: block;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      #flexible-subtitle-mobile-1 {
        display: none;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      #flexible-subtitle-mobile-2 {
        display: none;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      @media screen and (max-width: 768px) {
        align-items: center;
      }

      @media screen and (max-width: 425px) {
        align-items: flex-start;
      }

      .flexible-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 1em;

        .flexible {
          display: flex;
          justify-content: center;
          width: 50%;
          padding: 1em;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          @media screen and (max-width: 425px) {
            padding: 2rem 4rem;
          }
          .items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            color: #1d2332;
            max-width: 292px;

            .img-wrapper {
              // width: 30px;
              // height: 30px;
              margin-bottom: 1em;
            }
            p.title {
              font-family: RubikBold;
              font-size: 18px;
              margin: 0;
              padding: 0;
              margin-bottom: 1rem;
              line-height: 26px;
            }
            p.content {
              font-size: 15px;
              margin: 0;
              padding: 0;
              opacity: 0.7;
              line-height: 24px;
              .price {
                font-family: RubikBold;
              }
            }

            @media screen and (max-width: 768px) {
              align-items: center;
              text-align: center;
              p.content {
                font-size: 1.3rem;
              }
            }
            @media screen and (max-width: 425px) {
              p.content {
                font-size: 14px;
                line-height: 24px;
              }
            }
          }
        }
        @media screen and (max-width: 768px) {
          justify-content: center !important;
        }
      }
    }
  }

  .apply-now {
    @media screen and (max-width: 425px) {
      font-size: 20px !important;
      line-height: 24px !important;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  .service-group-title {
    font-size: 20px;
    line-height: 24px;
    font-family: RubikMedium;
  }

  .service-section {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #fafafa;
    .service-wrapper {
      width: 100%;
      max-width: 905px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding: 5rem 0;
      padding-bottom: 2rem;
      @media screen and (max-width: 1024px) {
        padding-left: 4rem;
        padding-right: 4rem;
      }
      .service-group {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .service {
          max-width: 200px;
          max-height: 100%;
          margin: 2em 0;
          position: relative;
          p {
            text-align: center;
            font-family: RubikMedium;
            font-size: 15px;
            line-height: 24px;
            margin: 0;
            margin-top: 1em;
            color: #1d2332;
          }
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          .service {
            max-width: 20rem;
            p {
              font-size: 15px;
              line-height: 24px;
            }
          }
        }
      }
    }
  }

  .about-us {
    width: 100%;
    padding: 72px 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    .about-us-container {
      display: flex;
      max-width: 55.5625rem;

      .about-note {
        width: 50%;

        .service--title {
          font-size: 20px;
          line-height: 24px;
          text-align: left;
          font-family: RubikMedium;

          @media only screen and (max-width: 768px) {
            text-align: center;
          }

          @media only screen and (max-width: 425px) {
            text-align: left;
          }
        }

        .about-note-p {
          font-family: RubikRegular;
          color: #a5a7ad;
          font-size: 15px;
          line-height: 20px;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
          padding: 48px 32px 0;
        }
      }

      .about-pic {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 313px;
          height: auto;

          @media only screen and (max-width: 768px) {
            width: 100%;
            height: auto;
            max-width: 768px;
          }
        }

        @media only screen and (max-width: 768px) {
          margin-top: -72px;
          width: 100%;
        }
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        background-color: #fafafa;
      }
    }

    @media only screen and (max-width: 768px) {
      padding-bottom: 0px;
    }
  }

  .locations {
    max-width: 400px;
    width: 100%;
    margin-bottom: 3.75rem;

    .location-title {
      font-family: RubikMedium;
      font-size: 20px;
      line-height: 24px;
    }

    .main-title {
      text-align: center;
      margin-bottom: 1rem;

      @media only screen and (max-width: 768px) {
        text-align: left;
      }
    }

    .locations p {
      text-align: center;
      max-width: 400px;
      margin:0 30px 50px;
    }

    .location-note {
      color: #a5a7ad;
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
      text-align: center;
      font-family: RubikRegular;

      @media only screen and (max-width: 768px) {
        text-align: left;
      }
    }

    @media only screen and (max-width: 768px) {
      max-width: 100%;
      padding: 0 2rem;
    }
  }

  .popular--location {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    padding: 40px 0 64px 0;

    .location-title {
      font-family: RubikMedium;
      font-size: 20px;
      line-height: 24px;
    }

    .location-note {
      font-family: RubikRegular;
      color: #a5a7ad;
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
    }

    .popular-location-cards {
      display: flex;
      width: 100%;
      max-width: 933px;
      justify-content: space-between;

      .location-card {
        width: 33.3%;
        max-width: 277px;

        img {
          margin: 30px 0;
          width: 100%;
          height: auto;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
          max-width: 357px;
        }
      }

      @media only screen and (max-width: 768px) {
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }

    @media only screen and (max-width: 768px) {
      padding: 70px 32px 64px 32px;
      align-items: center;
    }

    @media only screen and (max-width: 425px) {
      padding: 70px 32px 64px 32px;
      align-items: start;
    }
  }

  .side__slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1 0 auto;
    width: 100%;
    position: relative;
    padding: 4.38rem 0 5rem;
    margin-left: -15%;

    .side__wrapper {
      display: flex;
      flex-direction: row;
      max-width: 1330px;
      width: 100%;
    }

    .left__slider {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 5;
      position: relative;
      .reason--using {
        max-width: 28.125rem;
        width: 100%;
        z-index: 1;
        transform: translateX(6%);
        p.title {
          font-size: 44px;
          transform: translateX(10%);
          text-align: left;
        }
        .card__items {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: start;
          max-width: 26.875rem;
          width: 100%;
          img {
            max-width: 30px;
            width: 100%;
            max-height: 44px;
            height: 100%;
            margin-right: 1rem;
            margin-top: 1.2rem;
          }
          p {
            font-size: 18px;
            line-height: 1.4444;
          }
        }
        .mobile--download {
          justify-content: flex-start;
          margin-top: 2rem;
          transform: translateX(10%);
        }
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .right__slider {
      display: flex;
      flex: 5;
      position: relative;
      color: #fff;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      max-width: 665px;
      max-height: 675px;

      .group--text {
        position: absolute;
        bottom: 20.5%;
        left: 11.87%;

        p {
          margin: 25px 0;
          max-width: 408px;
          font-family: RubikRegular;


          &:first-of-type {
            font-size: 24px;
            line-height: 1.1875;
            font-family: RubikBoldItalic;
          }
          &:last-of-type {
            font-size: 16px;
            line-height: 3.07;
            font-family: RubikMedium;
          }
        }
      }
      .bullet__slider {
        position: absolute;
        bottom: 9%;
        left: 12.48%;
        display: flex;
        flex-direction: column;
        z-index: 1;

        .bullet {
          height: 0.7em;
          width: 0.7em;
          background: #fff;
          border-radius: 50%;
          margin: 0.5em 0;
          opacity: 0.5;
          &.active {
            opacity: 1;
          }
        }
      }

      .photo__slider {
        position: absolute;
        top: 0%;
        left: 0%;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;

        .photo {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: opacity 0.5s linear;
          object-fit: cover;

          &.active {
            transition: opacity 0.5s linear;
            opacity: 1;
          }
        }
      }
    }
  }

  #location-mobile {
    display: none;
    margin-top: 48px;

    @media only screen and (max-width: 768px) {
      display: block;
    }
  }

  #location-web {
    display: block;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .join-travelpix-section {
    display: flex;
    background-color: #fbca00;
    width: 100%;
    justify-content: center;
    .join-travelpix-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 2rem 0;
      max-width: 661px;
      @media screen and (max-width: 425px) {
        padding: 2rem 4rem;
        p.text-slogan {
          font-size: 22px !important;
          line-height: 28px !important;
        }
      }
      p.text-slogan {
        text-align: left;
        margin: 0;
        color: #1d2332;
        font-size: 30px;
        line-height: 40px;
        font-family: RubikRegular;
      }
      .join-travelpix {
        max-width: 217px;
        font-size: 15px;
        padding: 12px 22px;
        font-family: RubikMedium;

        .apply-icon {
          margin: 0 0 0 22px;
          width: 15px;
          height: 10px;
        }

        @media screen and (max-width: 768px) {
          max-width: 100%;
          width: 50%;
          justify-content: space-between;
        }

        @media screen and (max-width: 425px) {
          max-width: 100%;
          width: 100%;
          justify-content: space-between;
        }
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
        p.text-slogan {
          text-align: center;
          padding: 0;
          padding-top: 1em;
        }
      }
    }
  }

  @media only screen and (max-width: 1120px) {
    .side__slider {
      margin-left: -15%;

      .left__slider {
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        justify-content: center;
        .reason--using {
          transform: translateX(16%);
          p.title {
            transform: translateX(8%);
          }

          .card__items {
            flex-direction: row;
            text-align: center;
            margin: 20px 0;

            img {
              margin-right: 0rem;
            }
            p {
              padding: 0 1.5rem;
            }
          }
        }
        .title {
          margin: 0;
          text-align: center;
        }
      }
      .right__slider {
        .group--text {
          margin-top: 25px;
          max-width: 60%;
          top: 14%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .mobile--download {
        display: flex;
        transform: translateX(0);
      }
    }
  }

  @media only screen and (max-width: 812px) {
    .side__slider {
      margin-left: 0%;

      .left__slider {
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        justify-content: center;

        .reason--using {
          transform: translateX(10%);

          p.title {
            transform: translateX(0%);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .side__slider {
      padding-top: 42px;
      padding-bottom: 0;
      margin-left: 0%;

      .side__wrapper {
        flex-direction: column;
      }
      margin-top: 30px;
      flex-direction: column;
      align-items: center;

      .left__slider {
        .reason--using {
          padding: 0 0 80px 0;
          transform: translateX(0%);

          .title {
            padding-bottom: 24px;
          }

          .card__items {
            opacity: 1;
            flex-direction: column;
            margin: 0;
            align-items: center;
            padding: 0 10px;
            box-shadow: none;
          }

          .active {
            background: white;
          }

          p.title {
            font-size: 24px;
            line-height: 1.16666;
            text-align:center;
            transform:translateX(0);
          }
        }
      }
      .right__slider {
        padding: 72% 50%;
        background-repeat: no-repeat;
        background-size: cover;

        .group--text {
          min-width: 241px;
          max-height: 15rem;

          p {
            margin: 0;
            max-width: 408px;
            font-family: RubikRegular;
            text-align: center;

            &:first-of-type {
              font-size: 24px;
              line-height: 1.1875;
              font-family: RubikBoldItalic;
            }
            &:last-of-type {
              font-size: 16px;
              line-height: 3.07;
              font-family: RubikMedium;
            }
          }
        }

        .bullet__slider {
          left: 50%;
          top: 80%;
          bottom: 0%;
        }
      }
    }
  }

  @media screen and (max-width: 425px) {
    .side__slider {
      padding-top: 42px;
      margin-left: 0%;

      .left__slider {
        .reason--using {
          padding: 0 0 80px 0;
          transform: translateX(0%);
          width: auto;

          .title {
            padding-bottom: 24px;
          }

          .card__items {
            opacity: 1;
            align-items: center;
            margin: 0;
            padding: 0 10px;
            box-shadow: none;

            p {
              padding: 10px;
            }
          }

          .active {
            background: white;
          }
        }
      }
      .right__slider {
        padding: 0;
        min-height: 34rem;
        background-repeat: no-repeat;
        background-size: cover;

        .group--text {
          min-width: 241px;

          p {
            text-align: center;

            &:first-of-type {
              margin-top: 25px;
              font-size: 18px;
              line-height: 1.6666;
              text-align: center;
            }
            &:last-of-type {
              text-align: center;
              font-size: 16px;
              line-height: 2.307;
              font-family: RubikMedium;
              text-align: center;
            }
          }
        }

        .bullet__slider {
          left: 50%;
          top: 80%;
          bottom: 0%;
        }
      }
    }
  }
`;

export { PhotographerPageStyle };

/* eslint-disable  max-len */
export const locationStaticData = [
  {
    title: 'Tower Bridge',
    img: '/landing-page/tower-bridge.png',
    note: `Capture the beauty, majesty & size of Tower Bridge from our spot. On the banks of the Thames, the castle-like bridge, the river and London's icon skyline blend together to create an amazing backdrop.`,
  },
  {
    title: `Neal's Yard`,
    img: '/landing-page/neal-yard.png',
    note: `An oasis of bright colours, exposed brickwork and quaint shops. Now home to several organic cafes, food stores and the world famous Neal’s Yard Remedies, this spot is definitely a must-see while in London.`,
  },
  {
    title: 'Big Ben',
    img: '/landing-page/big-ben.png',
    note: `Big Ben is a British icon. It showcases the cities beauty, architecture and history. A trip to London is not complete without a photo with Big Ben.`,
  },
];

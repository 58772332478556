import styled from '@emotion/styled';

const MobileAppDownloadStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  a {
    margin: 0px 10px;

    img {
      max-width: 100%;
      height: 100%;
      max-height: 40px;
    }
  }

  .appstore {
    max-width: 120px;
  }

  .playstore {
    max-width: 135px;
  }
`;
export { MobileAppDownloadStyle };

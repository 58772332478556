export const commentData = [
  {
    message: "\"I was looking for a way to top up my income as a freelance photographer. Travelpix is perfect for this and I have the flexibility to work my own hours.\"",
    author: "Sabrina, TravelPix Photographer",
    image: "/slider-intro/photographer-testimonial-1.png"
  },
  {
    message: "\"It is perfect for me because I work as a freelance photographer. This helps me fill my schedule when I'm less busy with client work.\"",
    author: "Nathan, TravelPix Photographer",
    image: "/slider-intro/photographer-testimonial-2.png"
  },
  {
    message: "\"I can’t believe this hasn’t existed before. It combines two of my favourite things: photography & people\"",
    author: "Olive, TravelPix Photographer",
    image: "/slider-intro/photographer-testimonial-3.png"
  }
]

import React, { FunctionComponent, useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { navigate } from '@reach/router';
import Layout from '../../layouts/Layout';
import MobileAppDownload from '../../components/MobileAppDownload';
import { PhotographerPageStyle } from './PhotographerPageStyle';
import Button from '../../components/Form/Button';
import ImageIcon from '../../components/ImageIcon';
import { Form } from 'react-final-form';
import FormField from '../../components/Form/FormField';
import { locationStaticData } from '../LandingPage/locationUtils';
import Card from '../../components/Card';
import { commentData } from './comments';
import { isMobileOnly } from 'react-device-detect';
import { Helmet } from 'react-helmet';

export interface CommentData {
  message: string,
  author: string,
  image: string
}

const emailRegrex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PhotographerPage: FunctionComponent<{}> = () => {
  var formValues: any = null;

  const [bulletId, setBulletId] = useState<string>('');
  const [cardId, setCardId] = useState<string>('');
  const [comment, setComment] = useState<CommentData>(commentData[0]);
  var position = 0;

  useEffect(() => {
    setCardId('card__1');
    setBulletId('bullet__1');

    const timer = setInterval(() => {
      position = position + 1;
      if (position >= commentData.length) {
        position = 0;
      }
      changeComment(position);
    }, 20000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onHoverSlogan = (id: string, position: number) => {
    if (isMobileOnly) {
      return;
    }
    setCardId(id);
    position = position;
    changeComment(position);
  };

  const changeComment = (position: number) => {
    setComment(commentData[position]);
    setBulletId(`bullet__${position + 1}`);
  }

  const changeCommentDot = (event: any) => {
    let position = event.target.id.replace(/\D/g, '');
    changeComment(parseInt(position) - 1);
  }

  const gotoRegisterPage = () => {
    navigate('/register');
  };

  const onSubmitForm = () => {
    navigate(`/register?firstName=${formValues.firstName}&email=${encodeURIComponent(formValues.email)}`);
  }

  return (
    <Layout isPhotographerPage>
      <PhotographerPageStyle>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Photographer</title>
        </Helmet>
        <div className="header-container">
          <div className="header-group">
            <div className="left-content">
              <div className="caption">
                <span>Start earning<br></br>money by taking great TravelPix.</span>
              </div>
              <div className="text-intro">
                Capture timeless holiday memories as a Travelpix Photographer and get paid!
              </div>

              <Form
                initialValues={{ firstName: '', email: '' }}
                onSubmit={onSubmitForm}
                validate={values => {
                  const errors = {};
                  if (!values.firstName) {
                    errors.firstName = 'This field is required';
                  }
                  if (!values.email || !emailRegrex.test(values.email)) {
                    errors.email = 'Please input a valid email';
                  }
                  return errors;
                }}
                render={({ handleSubmit, values }) => {
                  formValues = values;
                  return (
                    <form className="group-input" onSubmit={handleSubmit}>
                      <FormField
                        className="input-control"
                        type="text"
                        label="Your first name"
                        name="firstName"
                        offsetTopMoved="7px"
                        widthPercent="48%" />
                      <span className="spacing" />
                      <FormField
                        className="input-control"
                        type="text"
                        label="Your email"
                        name="email"
                        offsetTopMoved="7px"
                        widthPercent="48%" />
                      <div>
                        <Button
                          css={css`
                                margin: 1rem auto 1.56rem auto;
                            `}
                          icon={<ImageIcon className="apply-icon" src="/icon/white_right_arrow.svg" />}
                          label="Apply now"
                          background="#1E2433"
                          color="#fff"
                          className="apply-btn"
                          type="submit"
                        />
                      </div>
                    </form>
                  )
                }} />
            </div>
            <div className="right-content">
              <div className="img-wrapper">
                <img src="/photographer-intro/lens.png" alt="lens background" />
              </div>
            </div>
          </div>
        </div>
        <div className="flexible-section">
          <div className="flexible-container">
            <p className="flexible-title">A flexible Schedule. A stable income. 100% photography.</p>
            <p className="flexible-subtitle">
              Travel and photos are a natural fit. Capturing memories is a big part of holidays, and we think it should be easy for everyone to get high quality photographs wherever they go.
            </p>
            <p className="flexible-subtitle" id="flexible-subtitle-web">
              Our mission is simple: We want to offer everyone great professional photos at epic locations for a fair price. Join our community of friendly professional photographers and capture great photos of families and friends at landmarks and popular spots in London.
            </p>
            <p className="flexible-subtitle" id="flexible-subtitle-mobile-1">
              Our mission is simple: We want to offer everyone great professional photos at epic locations for a fair price.
            </p>
            <p className="flexible-subtitle" id="flexible-subtitle-mobile-2">
              Join our community of friendly professional photographers and capture great photos of families and friends at landmarks and popular spots in London.
            </p>
            <div className="flexible-group">
              <div className="flexible">
                <div className="items">
                  <div className="img-wrapper">
                    <img src="/photographer-icon/moneybag.svg" alt="moneybag.svg" />
                  </div>
                  <p className="title">A steady Income</p>
                  <p className="content">
                    Earn as much as <span className="price">£250</span> per Session. The more photos they buy, the more you earn. Get up to
                    £50 for each person you refer
                  </p>
                </div>
              </div>
              <div className="flexible">
                <div className="items">
                  <div className="img-wrapper">
                    <img src="/photographer-icon/photography.svg" alt="photography.svg" />
                  </div>
                  <p className="title" css={css`margin-top: 7px !important;`}>Full-Time Photography</p>
                  <p className="content">
                    Take your skills to the next level: perfect your work with the camera and gain experience working with travelers
                  </p>
                </div>
              </div>
              <div className="flexible">
                <div className="items">
                  <div className="img-wrapper">
                    <img src="/photographer-icon/calendar.svg" alt="calendar.svg" />
                  </div>
                  <p className="title">Flexible work</p>
                  <p className="content">
                    Fit TravelPix around your existing projects. Choose your own hours with no long-term commitments
                  </p>
                </div>
              </div>
              <div className="flexible">
                <div className="items">
                  <div className="img-wrapper">
                    <img src="/photographer-icon/Time.svg" alt="Time.svg" />
                  </div>
                  <p className="title">Time worked = Time paid</p>
                  <p className="content">
                    No more unpaid time finding clients or editing photos - focus on taking photos with transparency on earnings per hour
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-slogan apply-now">
          <span className="primary-text" onClick={gotoRegisterPage}>
            {' '}
            &#00;Apply Now!
          </span>
        </p>

        <div className="service-section">
          <div className="service-wrapper">
            <p className="service-group-title">How it works</p>
            <div className="service-group">
              <div className="service">
                <div className="img-wrapper">
                  <img src="/photographer-intro/1.png" alt="Ads 1" />
                </div>
                <p>Pick one of the available slots (time/place) that suits you</p>
              </div>
              <div className="service">
                <div className="img-wrapper">
                  <img src="/photographer-intro/2.png" alt="Ads 2" />
                </div>
                <p>Shoot mini sessions with tourists at the chosen location</p>
              </div>
              <div className="service">
                <div className="img-wrapper">
                  <img src="/photographer-intro/3.png" alt="Ads 3" />
                </div>
                <p>Upload the photos directly to the TravelPix gallery and get paid without hassle</p>
              </div>
            </div>
          </div>
        </div>

        <section id="location-mobile" className="locations">
          <div className="location-title main-title">Locations</div>
          <p className="location-note">
            People come from all over the world to visit London's most iconic landmarks. All our locations are carefully handpicked to
            ensure fantastic photo ops and high footfall. They include epic historical landmarks, beautiful scenic backdrops and local
            "hidden" gems. Here are a few of our favourites.
          </p>
        </section>

        <MobileAppDownload
          className="mobile--download"
          css={css`
            padding-bottom: 35px;
            display: none;
            width: 120px;

            @media only screen and (max-width: 768px) {
              display: block;
              padding-top: 0px;
              margin-top: -40px;
              margin-right: 30%;
              padding-bottom: 72px;
            }

            @media only screen and (max-width: 425px) {
              display: block;
              padding-top: 0px;
              margin-top: -40px;
              margin-right: 56%;
              padding-bottom: 72px;
            }
          `} />

        <section className="about-us">
          <div className="about-us-container">
            <div className="about-note">
              <p className="service--title">About us</p>
              <p className="about-note-p">
                Travel and Photos are a natural fit. Capturing memories with family &amp; friends is a big part of our holidays, and we think it
                should be easy to get great photos. Enough of selfie-sticks, having to ask strangers or not being able to include everyone
                in the photo. Our mission is simple: We want to offer everyone great professional photos at epic locations for a fair price.
              </p>
              <p className="about-note-p">
                TravelPix is a team of friendly professional photographers ready to capture your best side at landmarks and popular spots in
                London. No need to plan, reserve or commit. We know the best backdrops, angles and poses and will capture the best memories
                of your trip.
              </p>
              <p className="about-note-p">
                Have your photo taken for free with no commitment and see if you like it. If you do, and we are confident you will - great -
                it’s super easy to order. If you don’t - no hard feelings. We know how much love there is out there for the #selfie.
              </p>
            </div>

            <div className="about-pic">
              <img src="/landing-page/about-us.png" alt="about-us" />
            </div>
          </div>
        </section>

        <MobileAppDownload
          className="mobile--download"
          css={css`
            padding-bottom: 35px;

            @media only screen and (max-width: 768px) {
              display: none;
            }
          `} />

        <section id="location-web" className="locations">
          <div className="location-title main-title">Locations</div>
          <p className="location-note">
            People come from all over the world to visit London's most iconic landmarks. All our locations are carefully handpicked to
            ensure fantastic photo ops and high footfall. They include epic historical landmarks, beautiful scenic backdrops and local
            "hidden" gems. Here are a few of our favourites.
          </p>
        </section>

        <section className="popular--location">
          <div className="location-title">Popular locations</div>
          <div className="popular-location-cards">
            {locationStaticData.map((location: any) => (
              <div className="location-card" key={location.title}>
                <img src={location.img} alt={location.title} />
                <div className="location-title">{location.title}</div>
                <div className="location-note">{location.note}</div>
              </div>
            ))}
          </div>
        </section>

        <section className="side__slider">
          <div className="side__wrapper">
            <div className="left__slider">
              <div className="reason--using">
                <p className="title">
                  <span>Join the TravelPix</span> <br />
                  <span>community</span>
                </p>
                <Card className={cardId === 'card__1' ? 'card__items active' : 'card__items'} id="card__1" position={0} onHover={onHoverSlogan}>
                  <img src="/slider-intro/earn-income.svg" alt="Photographer" />
                  <p>
                    <span>Earn a steady income</span> of as much as £250 per session. Get up to £50 for each person you refer.
                  </p>
                </Card>
                <Card className={cardId === 'card__2' ? 'card__items active' : 'card__items'} id="card__2" position={1} onHover={onHoverSlogan}>
                  <img css={css`max-width: 36px !important;`} src="/slider-intro/fulltime-photography.svg" alt="Photographer" />
                  <p>
                    <span>Full-time photography</span> to take your skills to the next level
                  </p>
                </Card>
                <Card className={cardId === 'card__3' ? 'card__items active' : 'card__items'} id="card__3" position={2} onHover={onHoverSlogan}>
                  <img src="/slider-intro/flexible-work.svg" alt="Sharing on mobile" />
                  <p>
                    <span>Flexible work</span> to fit around your existing projects.
                  </p>
                </Card>
                <MobileAppDownload
                  css={css`
                    padding-top: 20px;
                    margin-top: 2rem;

                    @media screen and (max-width: 768px) {
                      display: none !important;
                    }
                  `}
                  className="mobile--download"
                />
              </div>
            </div>
            <div className="right__slider">
              <div className="photo__slider">
                <img
                  src={commentData[0].image}
                  alt='Photographer-testimonial-1'
                  className={bulletId === 'bullet__1' ? 'photo active' : 'photo'} />
                <img
                  src={commentData[1].image}
                  alt='Photographer-testimonial-2'
                  className={bulletId === 'bullet__2' ? 'photo active' : 'photo'} />
                <img
                  src={commentData[2].image}
                  alt='Photographer-testimonial-3'
                  className={bulletId === 'bullet__3' ? 'photo active' : 'photo'} />
              </div>
              <div className="group--text">
                <p>{comment.message}</p>
                <p>{comment.author}</p>
              </div>
              <div className="bullet__slider">
                <span id={'bullet__1'} className={bulletId === 'bullet__1' ? 'bullet active' : 'bullet'} onClick={changeCommentDot} />
                <span id={'bullet__2'} className={bulletId === 'bullet__2' ? 'bullet active' : 'bullet'} onClick={changeCommentDot} />
                <span id={'bullet__3'} className={bulletId === 'bullet__3' ? 'bullet active' : 'bullet'} onClick={changeCommentDot} />
              </div>
            </div>
          </div>
        </section>

        <div className="join-travelpix-section">
          <div className="join-travelpix-group">
            <p className="text-slogan">Start earning money by taking great TravelPix.</p>
            <Button
              icon={<ImageIcon src="/icon/white_right_arrow.svg" className="apply-icon" />}
              label="Join Travelpix"
              color="#fff"
              background="#1D2332"
              className="join-travelpix"
              onClick={gotoRegisterPage}
            />
          </div>
        </div>

      </PhotographerPageStyle>
    </Layout>
  );
};

export default PhotographerPage;
